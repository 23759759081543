import * as React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Seo from '../components/seo'

const SitemapPage = () => {
  const mainMenu = [
    {
      title: 'Home',
      alt: 'Home',
      url: '/',
    },
    {
      title: 'Gallery',
      alt: 'Gallery',
      url: '/gallery/',
    },
    {
      title: 'Commercial Kitchen Gallery',
      alt: 'Commercial Kitchen Gallery',
      url: '/gallery/commercial-kitchen-gallery/',
    },
    {
      title: 'Commercial Sinks Gallery',
      alt: 'Commercial Sinks Gallery',
      url: '/gallery/commercial-sinks-gallery/',
    },
    {
      title: 'Commercial Brewery Gallery',
      alt: 'Commercial Brewery Gallery',
      url: '/gallery/commercial-brewery-gallery/',
    },
    {
      title: 'Misc Projects Gallery',
      alt: 'Misc Projects Gallery',
      url: '/gallery/misc-gallery/',
    },
    {
      title: 'Contact Us',
      alt: 'Contact Us',
      url: '/contact/',
    },
  ]
  return (
    <Layout>
      <Seo
        title="Sitemap Page Kustom Kreations Welding"
        description="Hard Sitemap"
      />
      <div
        style={{
          margin: `2rem auto`,
          maxWidth: `1920px`,
        }}
      >
        <h1>Sitemap for Kustom Kreations Welding</h1>
        <ul>
          {mainMenu.map(({ title, url }) => (
            <li key={title}>
              <Link to={url} alt={title}>
                {title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </Layout>
  )
}

export default SitemapPage
